import React from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import PlayButton from './images/playstore.png'
import { Suspense } from 'react'
const TextContent = React.lazy(() => import('./TextContent'))
function Footer() {
    const share = async () => {
        if (navigator.share) {
            await navigator.share({
                title: 'Send Whatsapp message to anyone without saving number',
                text: 'An awesome app designed to WhatsApp anyone without saving number',
                url: 'https://wandapp.touristerguide.com/?utm-src=web_share',
                // files: [PlayButton],
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }
    return (
        <>
            <Container maxWidth="sm">
                <div style={{ justifyContent: 'space-around', display: 'flex' }}>
                    <Button
                        component='a'
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.touristerguide.wandapp&utm_source=wandapp&
                        utm_campaign=androidappdownload&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" variant="text" color="inherit" >
                        <img component={Button} src={PlayButton} height={35} alt="Play Store" />
                    </Button>
                    <Button onClick={share} variant="outlined" >
                        Share
                    </Button>
                </div>
                <Suspense fallback={<>Loading...</>}>
                    <TextContent />
                </Suspense>

            </Container>
        </>
    )
}

export default Footer
